import React from 'react';
import './Body.css'

const TextAbout = () => {
    return (
        <div className='TextAbout'>
           <p>Welcome</p>
           <p>Front-end</p>
           <p>Development</p>
           <p>Services</p>
        </div>
    );
};

export default TextAbout;