import React from 'react';
import Header from './Header/Header'
import Body from './Body/Body'
import Email from './Body/Email'


function App() {
  return (
    <div className="App">
    <Header/>
    <Body/>
    
    <Email/>
    
     
    </div>
  );
}

export default App;
