import React from 'react';

const Close = () => {
    return (
        <div className='Close'>
        <p>Close</p>
        <div className='closeCircle'></div>
        <div className='closeRectangle'></div>
         
        </div>
    );
};

export default Close;