import React from 'react';
import './Body.css'
import Niko from './Niko'
import Rectangle from './Rectangle'


const Body = () => {
    return (
        <div className='Body'>
    <Niko/>
    <Rectangle/> 
   
    
     </div>
    );
};

export default Body;